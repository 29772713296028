import { getMlDdStreamUrl } from "../constants/endpoints";
class SocketService {
  constructor() {
    if (!SocketService.instance) {
      this.socket = null;
      this.listeners = new Set(); // Store message listeners
      this.isConnecting = false; // Allow state changes
      SocketService.instance = this;
    }

    return SocketService.instance;
  }

  getSocketConnection(userId) {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      if (this.isConnecting) {
        console.warn("WebSocket is already connecting...");
        return;
      }

      this.isConnecting = true;
      this.socket = new WebSocket(getMlDdStreamUrl(userId));

      this.socket.onopen = () => {
        this.isConnecting = false;
        console.log("WebSocket connection established.");
      };

      this.socket.onclose = ev => {
        this.isConnecting = false;
        console.log("WebSocket connection closed.", ev);
        // Optionally, you can add reconnection logic here
      };

      this.socket.onerror = error => {
        console.error("WebSocket error:", error);
      };

      this.socket.onmessage = event => {
        const message = JSON.parse(event.data);
        if (message.type === "ping") {
          this.sendMessage({ type: "pong" });
        } else {
          this.listeners.forEach(callback => callback(message));
        }
      };
    }
  }

  closeConnection() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.isConnecting = false;
      console.log("WebSocket connection closed manually.");
    }
  }

  sendMessage(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket is not open. Cannot send message.");
    }
  }

  addMessageListener(callback) {
    if (typeof callback !== "function") {
      console.error("Callback must be a function.");
      return;
    }
    this.listeners.add(callback);
  }

  removeMessageListener(callback) {
    this.listeners.delete(callback);
  }
}

const socketServiceInstance = new SocketService();

// Freeze only the instance reference, not its properties
Object.defineProperty(socketServiceInstance, "instance", {
  writable: false, // Ensure the reference cannot be reassigned
});

export default socketServiceInstance;
