import { createSlice } from "@reduxjs/toolkit";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../constants/statuses";

export const INITIAL_STATE = {
  getUserConversationStatus: REQUEST_UNDEFINED,
  createUserConversationStatus: REQUEST_UNDEFINED,
  updateUserConversationStatus: REQUEST_UNDEFINED,
  saveUserConversationIdStatus: REQUEST_UNDEFINED,
  getPumaSummaryStatus: REQUEST_UNDEFINED,
  getConversationHistoryStatus: REQUEST_UNDEFINED,
  userConversationData: {}, // structure: sid: [{question: string, answer: string}]
  mlConversationId: null,
};

export const REDUCER_PREFIX = "userConversation";

const { reducer, actions } = createSlice({
  name: REDUCER_PREFIX,
  initialState: INITIAL_STATE,
  reducers: {
    setGetUserConversationPending: state => {
      state.getUserConversationStatus = REQUEST_PENDING;
    },
    setGetUserConversationSuccess: state => {
      state.getUserConversationStatus = REQUEST_SUCCESS;
    },
    setGetUserConversationFailure: state => {
      state.getUserConversationStatus = REQUEST_FAILURE;
    },
    setCreateUserConversationUndefined: state => {
      state.createUserConversationStatus = REQUEST_UNDEFINED;
    },
    setCreateUserConversationPending: state => {
      state.createUserConversationStatus = REQUEST_PENDING;
    },
    setCreateUserConversationSuccess: state => {
      state.createUserConversationStatus = REQUEST_SUCCESS;
    },
    setCreateUserConversationFailure: state => {
      state.createUserConversationStatus = REQUEST_FAILURE;
    },
    setUpdateUserConversationPending: state => {
      state.updateUserConversationStatus = REQUEST_PENDING;
    },
    setUpdateUserConversationSuccess: state => {
      state.updateUserConversationStatus = REQUEST_SUCCESS;
    },
    setUpdateUserConversationFailure: state => {
      state.updateUserConversationStatus = REQUEST_FAILURE;
    },
    setSaveUserConversationIdPending: state => {
      state.saveUserConversationIdStatus = REQUEST_PENDING;
    },
    setSaveUserConversationIdSuccess: state => {
      state.saveUserConversationIdStatus = REQUEST_SUCCESS;
    },
    setSaveUserConversationIdFailure: state => {
      state.saveUserConversationIdStatus = REQUEST_FAILURE;
    },
    setGetConversationHistoryPending: state => {
      state.getConversationHistoryStatus = REQUEST_PENDING;
    },
    setGetConversationHistorySuccess: state => {
      state.getConversationHistoryStatus = REQUEST_SUCCESS;
    },
    setGetConversationHistoryFailure: state => {
      state.getConversationHistoryStatus = REQUEST_FAILURE;
    },
    setGetConversationHistoryUndefined: state => {
      state.getConversationHistoryStatus = REQUEST_UNDEFINED;
    },
    setGetPumaSummaryPending: (state, { payload }) => {
      state.getPumaSummaryStatus = REQUEST_PENDING;
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_PENDING,
        };
      } else {
        state.userConversationData[payload.id].fetchPummaSummaryStatus =
          REQUEST_PENDING;
      }
    },
    setGetPumaSummarySuccess: (state, { payload }) => {
      state.getPumaSummaryStatus = REQUEST_SUCCESS;
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_SUCCESS,
        };
      } else {
        state.userConversationData[payload.id].fetchPummaSummaryStatus =
          REQUEST_SUCCESS;
      }
    },
    setGetPumaSummaryFailure: (state, { payload }) => {
      state.getPumaSummaryStatus = REQUEST_FAILURE;
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_FAILURE,
        };
      } else {
        state.userConversationData[payload.id].fetchPummaSummaryStatus =
          REQUEST_FAILURE;
      }
    },
    setMlConversationId: (state, { payload }) => {
      state.mlConversationId = payload.conversation_id;
    },
    // store ml response
    setCreateUserConversation: (state, { payload }) => {
      const coversationData = {
        isSummaryAnswer: false,
        companiesPreviewData: [],
        typing: true,
        question: payload.data.deep_dive_question,
        answer: null,
        characterPosition: null,
      };
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_UNDEFINED,
        };
        state.userConversationData[payload.id].conversation = [coversationData];
      } else {
        //workaround for rilters
        state.userConversationData[payload.id].conversation.push(
          coversationData,
        );
      }
    },
    // 1. push the new conversation object
    pushNewQuestion: (state, { payload }) => {
      const coversationData = {
        isSummaryAnswer: false,
        companiesPreviewData: [],
        typing: true,
        answer: null,
        characterPosition: null,
        question: payload.data.deep_dive_question,
      };
      //fix: starting a conversation with saved conversation_id
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_UNDEFINED,
        };
        state.userConversationData[payload.id].conversation = [coversationData];
      } else {
        state.userConversationData[payload.id].conversation.push(
          coversationData,
        );
      }
    },
    // 2. after the ai response find the last conversation object and update it with the ai answer
    setUpdateUserConversationLastQuestion: (state, { payload }) => {
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [{ answer: null, characterPositiona: null }],
          fetchPummaSummaryStatus: REQUEST_UNDEFINED,
        };
      }
      let lastQuestionIndex =
        state.userConversationData[payload.id].conversation.length - 1;
      if (lastQuestionIndex === -1) lastQuestionIndex = 0;
      state.userConversationData[payload.id].conversation[
        lastQuestionIndex
      ].answer = payload.response;
    },
    insertConversationHistory: (state, { payload }) => {
      const conversationData = {
        isSummaryAnswer: false,
        companiesPreviewData: [],
        typing: false,
        answer: null,
        characterPosition: null,
        question: null,
      };
      const conversation = [];
      if (payload.response.messages && payload.response.messages.length > 0) {
        payload.response.messages.forEach(message => {
          if (message.role === "user") {
            conversationData.question = message.content;
          } else {
            if (message.role === "event") {
              conversationData.question = "";
            }
            conversationData.answer = message.content;
            conversation.push({ ...conversationData });
          }
        });
      }

      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [],
          fetchPummaSummaryStatus: REQUEST_UNDEFINED,
        };
      }
      state.userConversationData[payload.id].conversation = [...conversation];
    },
    updateConversationTyping: (state, { payload }) => {
      if (payload.hasOwnProperty("typing")) {
        state.userConversationData[payload.id].conversation[
          payload.index
        ].typing = payload.typing;
      }
      if (
        payload.hasOwnProperty("characterPosition") &&
        state.userConversationData[payload.id].conversation[payload.index]
          .characterPosition === null
      ) {
        state.userConversationData[payload.id].conversation[
          payload.index
        ].characterPosition = payload.characterPosition;
      }
    },
    pushPumaSummary: (state, { payload }) => {
      const coversationData = {
        typing: true,
        characterPosition: null,
        answer: "<div><b>Summary of result</b></div>" + payload.response.answer,
        isSummaryAnswer: true,
        companiesPreviewData: [],
        question: null,
      };
      if (!state.userConversationData[payload.id]) {
        state.userConversationData[payload.id] = {
          conversation: [coversationData],
        };
      } else {
        let lastQuestionIndex =
          state.userConversationData[payload.id].conversation.length - 1;
        if (lastQuestionIndex === -1) lastQuestionIndex = 0;
        if (
          state.userConversationData[payload.id].conversation.length &&
          state.userConversationData[payload.id].conversation[lastQuestionIndex]
            .isSummaryAnswer
        ) {
          state.userConversationData[payload.id].conversation[
            lastQuestionIndex
          ] = coversationData;
        } else {
          state.userConversationData[payload.id].conversation.push(
            coversationData,
          );
        }
      }
    },
    setCompanyLinksData: (state, { payload }) => {
      state.userConversationData[payload.id].conversation[
        payload.index
      ].companiesPreviewData = payload.companiesPreviewData;
    },
    // should be used for loading the chat history
    setUserConversation: (state, { payload }) => {},
    emptyStorageConversation: state => {
      state.userConversationData = [];
    },
  },
});
export { reducer, actions };
