// Bases
const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const ML_URL = process.env.REACT_APP_ML_URL;
export const AI_URL = "https://ai.valuer.ai";
// Search
export const SECTORS_URL = `${API_URL}/sectors`;
export const SUBSECTORS_URL = `${API_URL}/subsectors`;
export const RESULTS_URL = `${API_URL}/results?`;
export const SEARCHES_URL = `${API_URL}/searches`;
export const getSearchUrl = sid => `${API_URL}/searches/${sid}`;
export const getCompanyFromSearchByMlIdUrl = (sid, mlId) =>
  `${API_URL}/searches/${sid}/company/${mlId}`;
export const COMPANIES_URL = `${API_URL}/companies`;
export const GET_SEARCH_RESULTS_URL = `${API_URL}/ml-search`;
export const getGenerateClusterData = (resourceType, resourceId, group) =>
  `${API_URL}/clusters/${resourceType}/${resourceId}/${group}?`;
export const SAVE_USER_CONVERSATION_ID_URL = `${API_URL}/puma/user-conversation/`;
export const CHAT_CONVERSATION_URL = `${API_URL}/puma/ask/ws`;
export const getMlSummaryUrl = sid => `${API_URL}/searches/${sid}/ml-summary`;
export const getSaveSummaryUrl = sid => `${API_URL}/searches/${sid}/summary`;
export const getConversationHistoryUrl = sid =>
  `${API_URL}/chat/history?sid=${sid}`;
// Auth
export const GET_COOKIES = `${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`;
export const POST_LOGIN = `${API_URL}/login`;
export const POST_REGISTER = `${API_URL}/register`;
export const POST_LOGOUT = `${API_URL}/logout`;
export const CHECK_RESET_LINK = `${API_URL}/password/check-reset-link`;
export const SEND_RESET_LINK = `${API_URL}/password/send-reset-link`;
export const RESET_PASSWORD = `${API_URL}/password/reset`;
export const GET_ME = `${API_URL}/me`;
export const PROFILE_UPDATE = `${API_URL}/profile/update`;
export const TEAM_URL = `${API_URL}/team`;
export const LEAVE_TEAM_URL = `${API_URL}/team/leave`;
export const ACCOUNT_URL = `${API_URL}/account`;
export const OPEN_CLUSTER_MAP_URL = `${API_URL}/cm`;
export const getInvitationUrl = token => `${API_URL}/invitation?token=${token}`;
export const getHandleInvitationUrl = token =>
  `${API_URL}/handle-invitation/${token}`;
export const getDeleteMemberUrl = email =>
  `${API_URL}/team/remove?email=${email}`;
// Mixpanel
export const MIXPANEL_TRACK_URL = `${API_URL}/mixpanel/track`;
// Projects
export const PROJECTS_URL = `${API_URL}/projects`;
export const PROJECT_UPDATE_URL = `${PROJECTS_URL}/update`;
export const PROJECT_UPDATE_ITEMS_URL = `${PROJECTS_URL}/update/items`;
export const COMPANY_DELETE_URL = `${PROJECTS_URL}/items/remove`;
export const getProjectStatusUrl = slug => `${PROJECTS_URL}/${slug}/status`;
export const getProjectUrl = slug => `${PROJECTS_URL}/${slug}`;
export const INVITE_COWORKERS_URL = `${API_URL}/invite?emails=`;
export const GET_COUNTRIES_URL = `${API_URL}/regions/countries`;
export const CHECK_RADAR_TITLE_URL = `${API_URL}/projects/titles`;
export const getCheckProjectsURL = (companyID, type) =>
  `${API_URL}/projects/add-check?item=${companyID}&type=${type}`;
// Company
export const getEnrichCompanyUrl = slug =>
  `${API_URL}/companies/${slug}/enrich`;
export const getCompanyUrl = (slug, cid) => `${API_URL}/companies/${slug}`;
export const getCompanyByIdUrl = id => `${API_URL}/companies/id/${id}`;
export const getCompanies = ids => `${API_URL}/companies/list?ids=${ids}`;
export const ENRICHED_EXAMPLE_COMPANY_URL = `${API_URL}/companies/enriched/deck`;
export const ENRICHED_EXAMPLE_TECHNOLOGY_URL = `${API_URL}/technologies/enriched/profile`;
export const COMPANY_GENERATE_LINK_URL = `${API_URL}/shared-link/get-company-link`;
export const ADD_COMPANY_COMMENTS_URL = `${API_URL}/comments`;
export const UPDATE_COMPANY_COMMENTS_URL = `${API_URL}/comments/update`;
export const DELETE_COMPANY_COMMENT_URL = `${API_URL}/comments/delete`;
export const VOTE_COMPANY_COMMENT_URL = `${API_URL}/comments/vote`;
export const getCompanyCommentsUrl = `${API_URL}/comments`;
export const POST_SHARED_LINK_URL = `${API_URL}/shared/link`;
export const FILTERING_API = `${API_URL}/results?`;
export const getCompanyTagsUrl = slug =>
  `${API_URL}/companies/tags/get-all/${slug}`;
export const ADD_COMPANY_TAGS_URL = `${API_URL}/companies/tags/add-new/`;
export const DELETE_COMPANY_TAGS_URL = `${API_URL}/companies/tags/delete/`;
export const SELECT_COMPANY_TAGS_URL = `${API_URL}/companies/tags/select/`;
export const VOTE_ML_RESULT_URL = `${API_URL}/companies/ml-result-vote`;
export const getDeckClientPreviewUrl = slug => `${API_URL}/deck/${slug}/get`;
export const COMPANIES_CHART_URL = `${COMPANIES_URL}/chart/years/analytics`;
export const TEAM_SIZE_CHART_URL = `${COMPANIES_URL}/chart/size/analytics`;
export const GET_SIMILAR_COMPANIES_URL = `${API_URL}/similar/get`;
export const GET_SUGGESTED_COMPANIES_URL = `${API_URL}/suggested/get`;
export const LIKED_COMPANIES_URL = `${API_URL}/companies/votes`;
// Credits
export const getBuyCreditsUrl = (amount, pageUrl) =>
  `${API_URL}/credits?amount=${amount}&page-url=${pageUrl}`;
export const getSubscribeUrl = (plan, period, amount, currency) =>
  `${API_URL}/subscribe?plan=${plan}&interval=${period}&currency=${currency}`;
export const INVOICES_URL = `${API_URL}/invoices`;
export const CREDITS_SUMMARY_URL = `${API_URL}/credits/summary`;
export const getPlanUrl = (plan, period, amount, currency) =>
  `${API_URL}/stripe/plan?plan=${plan}&interval=${period}&currency=${currency}`;
// Pricing url
export const getPricingPage = plan => {
  if (process.env.REACT_APP_ENV === "prod") {
    if (!!plan) {
      return `https://www.valuer.ai/pricing?upgrade=${plan}`;
    } else {
      return `https://www.valuer.ai/pricing`;
    }
  } else {
    if (!!plan) {
      //old pricing page
      //https://www.valuer.ai/pricing-0?hs_preview=dGIubMFD-56518171286
      return `https://www.valuer.ai/pricing-test?hs_preview=DEyMldhz-74648969595&upgrade=${plan}`;
    } else {
      return `https://www.valuer.ai/pricing-test?hs_preview=DEyMldhz-74648969595`;
    }
  }
};
export const removeProjectURL = slug => `${API_URL}/projects/${slug}/delete`;
//check search count
export const CHECK_SEARCH_COUNT_URL = `${API_URL}/sc`;
export const RESET_SEARCH_COUNT_URL = `${API_URL}/sc/reset`;
//Technologies
export const GET_ALL_TECHNOLOGIES_URL = `${API_URL}/technologies`;
export const getTechnologyURL = slug => `${GET_ALL_TECHNOLOGIES_URL}/${slug}`;
export const filterTechnologyCompaniesURL = slug =>
  `${GET_ALL_TECHNOLOGIES_URL}/${slug}/companies?`;
export const GET_TECHNOLOGY_COMPANY_PROFILE = `${GET_ALL_TECHNOLOGIES_URL}/companies`;
export const SUGGEST_TECHNOLOGY = `${GET_ALL_TECHNOLOGIES_URL}/suggest`;
export const postEnrichTechnologyCompanyURL = slug =>
  `${API_URL}/companies/${slug}/enrich`;
export const getEnrichTechnologyCompanyURL = slug =>
  `${GET_ALL_TECHNOLOGIES_URL}/${slug}/enrich`;
// Industries
export const INDUSTRIES_URL = `${API_URL}/industries`;
export const industriesCompaniesURL = slug =>
  `${INDUSTRIES_URL}/${slug}/companies?`;
export const getEnrichIndustryCompanyURL = slug =>
  `${INDUSTRIES_URL}/${slug}/enrich`;
//share link
export const SHARE_LINK_URL = `${API_URL}/search/generate`;
export const SHARE_LINK_DATA_URL = `${API_URL}/share/search/`;
//free trial
export const START_FREE_TRIAL = `${API_URL}/free-trials/create`;
export const FREE_TRIAL_TO_FREE_URL = `${API_URL}/free-trials/update-user-plan`;
export const EMAIL_VERIFICATION_URL = `${API_URL}/free-trials/send-email-verification`;
export const emailValidationURL = email =>
  `${API_URL}/free-trials/validate-email?email=${email}`;
// Orders
export const getOrdersUrl = (type, team) =>
  `${API_URL}/orders?type=${type}&team=${team}`;
export const getExportCsvUrl = (
  ids,
  type,
  idType,
  idValue,
  doc,
  sortBy,
  sortDirection,
  pageName,
) => {
  if (idType) {
    return `${API_URL}/download?selected=[${ids}]&type=${type}&${idType}=${idValue}&doc=${doc}${
      sortBy
        ? `&sort-by=${sortBy}&sort-direction=${sortDirection}${
            pageName ? `&page-name=${pageName}` : ""
          }`
        : `${pageName ? `&page-name=${pageName}` : ""}`
    }`;
  } else {
    return `${API_URL}/download?selected=[${ids}]&type=${type}&doc=${doc}${
      pageName ? `&page-name=${pageName}` : ""
    }`;
  }
};
// Dashboard
export const DASHBOARD_URL = `${API_URL}/dashboard`;
// Activities
export const ACTIVITIES_URL = `${API_URL}/activities`;
export const getActivitiesSeenUrl = id => `${ACTIVITIES_URL}/${id}/seen`;
export const PROMPT_TEST_URL = `${ML_URL}/scope_guide`;
export const PROMPT_CONSTRUCT_URL = `${ML_URL}/scope_guide_construct`;
export const CHAT_INTRO_URL = `${ML_URL}/chat/intro`;
export const CHAT_INTRO_URL_STREAM = `${ML_URL}/stream/chat/intro`;
export const EXISTING_INTRO_TEXT_URL = id => `${ML_URL}/chat/intro/${id}`;
export const SAVE_INTRO_ID_URL = `${API_URL}/scope/intro-id`;
export const ML_DD_STREAM_URL = `${ML_URL}/ws/chat/ask_async/8178723?username=${process.env.REACT_APP_ML_USER}&password=${process.env.REACT_APP_ML_PASSWORD}`;
export const getMlDdStreamUrl = userId =>
  `${ML_URL}/ws/chat/ask_async/${userId}?username=${process.env.REACT_APP_ML_USER}&password=${process.env.REACT_APP_ML_PASSWORD}`;
export const ML_PDF_STREAM_URL = `${ML_URL}/ws/chat/assistant/upload_pdf/8178723?username=${process.env.REACT_APP_ML_USER}&password=${process.env.REACT_APP_ML_PASSWORD}`;
export const SAVE_CONVERSATION_ID_URL = `${API_URL}/puma/user-conversation`;
