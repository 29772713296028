import React from "react";
import { Modal, Backdrop, Fade, Typography, Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: "#FFFFFF",
      borderRadius: "24px",
      padding: spacing(2, 4, 3),
    },
    modalTitle: {
      fontSize: "24px",
      fontFamily: "GT Flexa",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
    },
    nextBtn: {
      background: "#FFFFFF",
      color: "#666666",
      borderRadius: "30px",
      border: "1px solid #666666",
      textTransform: "none",
      fontFamily: "GT Flexa",
      "&:hover": {
        background: "#FD750D",
        color: "#FFFFFF",
        border: "1px solid",
      },
    },
    backBtn: {
      background: "#FD750D",
      borderRadius: "30px",
      color: "#FFFFFF",
      textTransform: "none",
      fontFamily: "GT Flexa",
      "&:hover": {
        background: "#0B295F",
      },
    },
  }),
);

const ActionModal = ({ open, onClose, title, content, onYes, onNo }) => {
  const classes = useStyles();

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // className={classes.modal}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography className={classes.modalTitle} variant="h6">
            {title}
          </Typography>
          <p
            style={{
              fontFamily: "GT Flexa",
              lineHeight: "normal",
            }}>
            {content}
          </p>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {onNo && (
              <Button className={classes.nextBtn} onClick={onNo}>
                No
              </Button>
            )}

            {onYes && (
              <Button
                style={{ marginLeft: "10px" }}
                className={classes.backBtn}
                onClick={onYes}>
                Yes
              </Button>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ActionModal;
