import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { useStyles } from "./style.js";
import Button from "../../../components/Button/Button.js";
import { ReactComponent as XIcon } from "../../../assets/icons/CloseIcon.svg";
import { prompts as examples } from "../../../constants/puma.js";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserConversationRequest,
  createUserConversationRequest,
} from "../../../store/userConversation/requests";
import { getUserSelector } from "../../../store/auth/selectors";

const SuggestedPromptsModal = ({
  contextObject,
  childActions,
  isOpen,
  setIsOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const [activeChip, setActiveChip] = useState({
    name: "",
    content: "",
  });
  // const [isOpen, setIsOpen] = useState(false);

  const handleModalSubmit = chip => {
    setIsOpen(false);

    let payload = {
      deep_dive_question: chip.content,
      conversation_context: contextObject.conversation_context,
      scope_description: contextObject.description,
      type: "chat",
      scope_guide_steps: {
        STEP_1: contextObject.scope_guide_step_1 || "empty",
        STEP_2: contextObject.scope_guide_step_2 || "empty",
        STEP_3: contextObject.scope_guide_step_3 || "empty",
        STEP_4: contextObject.scope_guide_step_4 || "empty",
        STEP_5: contextObject.scope_guide_step_5 || "empty",
      },
    };
    if (contextObject && contextObject.conversation_id) {
      payload.conversation_id = contextObject.conversation_id;
    }
    if (user && user.llm_region) {
      payload.llm_region = user.llm_region;
    }
    childActions();
    payload.conversation_id
      ? dispatch(
          updateUserConversationRequest({
            data: payload,
            id: contextObject.id,
          }),
        )
      : dispatch(
          createUserConversationRequest({
            data: payload,
            id: contextObject.id,
          }),
        );
  };
  const handleChipClick = example => {
    if (example.name === activeChip.name) {
      return setActiveChip({ name: "", content: "" });
    }
    setActiveChip(example);
  };

  return (
    <Dialog
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        scrollPaper: classes.dialogScrollPaper,
      }}
      open={isOpen}>
      <div className={[classes.close]} onClick={() => setIsOpen(false)}>
        <XIcon />
      </div>

      <div className={[classes.title]}>Propmpt Examples</div>
      <div className={[classes.description]}>
        For inspiration on how the Deep Dive prompting can be written for PUMA,
        please press any of the buttons below to see an example of previously
        popular prompts. You can either just use it directly, by pressing the
        prompt example button and then the “Use Example” button. Or you can
        simply take inspiration from a prompt example, and make your own free
        text version in the Deep Dive.
      </div>
      <div className={[classes.examples]}>
        {examples.map(example => (
          <div
            key={example.name}
            onClick={() => handleChipClick(example)}
            className={[
              example.name === activeChip.name
                ? classes.activeChip
                : classes.chip,
            ]}>
            {example.name}
          </div>
        ))}
      </div>
      <div className={[classes.divide]} />
      {activeChip.name && (
        <div className={[classes.selectedWrapper]}>
          <div>
            <div className={[classes.selectedTitle]}>{activeChip.name}</div>
            <div className={[classes.selectedDescription]}>
              {activeChip.content}
            </div>
          </div>
          <div className={[classes.button]}>
            <Button
              className={[classes.button]}
              variant="primary"
              disabled={false}
              onClick={() => handleModalSubmit(activeChip)}>
              Use Example
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default SuggestedPromptsModal;
