import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Typography from "../Typography/Typography";
import MlContent from "./component/MlContent";
import UserContent from "./component/UserContent";
import UserInput from "./component/UserInput";
import SuggestedPromts from "./component/SuggestedPromts";
import Button from "../Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./style.js";
import Tooltip from "@material-ui/core/Tooltip";
// Assets
import { ReactComponent as StopPuma } from "../../assets/icons/StopPUMA.svg";
import { ReactComponent as PDF } from "../../assets/icons/PDF.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  createUserConversationStatusSelector,
  getConversationHistoryStatusSelector,
  updateUserConversationStatusSelector,
  userConversationSelector,
} from "../../store/userConversation/selectors";
import {
  fetchPumaSummaryRequest,
  getConversationHistoryRequest,
} from "../../store/userConversation/requests";
import useSelectedFiltersCounter from "./hooks/useSelectedFiltersCounter";
// Constants
import { useAuthHook } from "../../common/useAuthHook";
import { actions as userConversationActions } from "../../store/userConversation/slice";
import { REQUEST_PENDING } from "../../constants/statuses.js";
import { useScopeGuidanceStore } from "../../store/scopeGuidance/store";
import ChatIntroService from "../../api/chatIntro/chatIntroService.js";
import Markdown from "../Markdown/Markdown";
import UserConversationService from "../../api/userConversation/userConversationService.js";
import SocketService from "../../services/socketService";
import {
  updateUserConversationRequest,
  createUserConversationRequest,
} from "../../store/userConversation/requests";
import PDFUpload from "../../pages/SearchCompanies/components/PdfUploader.js";
import SuggestedPromptsModal from "./component/SuggestedPromptsModal.js";
import ActionModal from "../Modal/ActionModal.js";

/**
 * MlChat component represents a chat interface for interacting with the PUMA (Valuer's Processing Unit for Multilayered Analysis) system.
 * It allows users to ask questions and receive responses related to search results and analysis.
 *
 * @component
 * @param {Object} contextObject - The context object containing information about the search context.
 * @param {string} contextName - The name of the search context.
 * @param {Array} selectedFilters - The selected filters for the search.
 * @param {Object} conversationContext - The conversation context.
 * @param {string} currentSearchId - The ID of the current search.
 * @param {boolean} resultsFetched - Indicates whether the search results have been fetched.
 * @returns {JSX.Element} The MlChat component.
 */
const MlChat = ({
  contextObject,
  contextName,
  selectedFilters,
  conversationContext,
  currentSearchId,
  resultsFetched,
  conversationId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const chatContentEl = useRef(null);
  const userConversationData = useSelector(userConversationSelector);
  const conversationHistoryStatus = useSelector(
    getConversationHistoryStatusSelector,
  );
  /* const pumaSummaryStatus = useSelector(getPumaSummaryStatusSelector);
  const userConversationStatus = useSelector(
      getUserConversationStatusSelector,
  ); */
  const updateUserConversationStatus = useSelector(
    updateUserConversationStatusSelector,
  );
  const createUserConversationStatus = useSelector(
    createUserConversationStatusSelector,
  );
  const [showLoading, setShowLoading] = useState(false);
  const [hasSummary, setHasSummary] = useState(null);
  const [scrollingDown, setScrollingDown] = useState(true);
  const prevScrollY = useRef(0);
  const [showSummaryLoading, setShowSummaryLoading] = useState(false);
  const [summaryHasErrorResponse, setSummaryHasErrorResponse] = useState(false);
  const [introText, setIntroText] = useState("");
  const [introId, setIntroId] = useState("");
  const [introSet, setIntroSet] = useState(false);
  const [isExampleModalOpen, setIsExampleModalOpen] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const streamingMessage = useRef("");

  //workaround - updating the conversation_context when filtering results. Remove conversation_id to start new conversation so that the context gets updated
  const { selectedFiltersCount } = useSelectedFiltersCounter({
    selectedFilters,
  });

  const { user } = useAuthHook();
  const chatIntroService = new ChatIntroService();
  const socketServiceRef = useRef(null);
  const [currentConversationID, setCurrentConversationID] = useState(null);

  function formatText(answer) {
    // Format headings
    answer = answer.replace(/^(#{1,4})\s(.+)$/gm, (match, hashes, sentence) => {
      const hashCount = hashes.length;
      const fontSize = 23 - hashCount; // Calculate font size based on hash count
      return `<span style="font-size:${fontSize}px; font-weight:bold;">${sentence}</span>`;
    });

    // Format bold text
    answer = answer.replace(/\*\*(.+?)\*\*/g, "<b>$1</b>");

    // Format italic text
    answer = answer.replace(/\*(.+?)\*/g, "<i>$1</i>");

    // Format links into citations
    answer = answer.replace(
      /(((http|https):\/\/)|(www\.))[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(:[0-9]+)?(\/\S*)?/g,
      match => {
        let url = match;
        if (!/^https?:\/\//.test(url)) {
          url = "http://" + url;
        }
        return `<a href="${url}" target="_blank" class="quotation_symbol" rel="noopener noreferrer" onclick="window.open('${url}', '_blank');">${url}</a>`;
      },
    );

    return answer;
  }

  //Handle incoming messages
  const handleConversation = payload => {
    setScrollingDown(true);
    prevScrollY.current = 0;

    if (payload.type === "streaming-start") {
      streamingMessage.current = "";
    }
    if (payload.answer) {
      if (streamingMessage.current === "") {
        setShowLoading(false);
        if (
          conversationContext &&
          conversationContext.conversation_id !== payload.conversation_id
        ) {
          setCurrentConversationID(payload.conversation_id);
        }
      }

      if (payload.type === "pdf-status") {
        streamingMessage.current = payload.answer;
        setIsPdfLoading(false);
      } else {
        streamingMessage.current += payload.answer;
      }

      dispatch(
        userConversationActions.setUpdateUserConversationLastQuestion({
          id: currentSearchId,
          response: formatText(streamingMessage.current),
        }),
      );
    }
    if (payload.type === "streaming-end") {
    }
  };

  useEffect(() => {
    if (currentConversationID && contextObject) {
      const userConversationService = new UserConversationService();
      userConversationService.saveUserConversationId({
        conversation_id: currentConversationID,
        resource_id: currentSearchId,
      });
      //Fast solution for turning conversation from creation to update in the user input
      contextObject.conversation_id = currentConversationID;
    }
  }, [currentConversationID]);

  useEffect(() => {
    if (!contextObject || introSet) {
      return;
    }

    if (contextObject.intro_id) {
      chatIntroService.getExistingIntro(contextObject.intro_id).then(data => {
        setIntroText(data);
      });
    } else {
      async function consumeStream(
        llm_id,
        scope_guide_steps,
        scope_description,
      ) {
        if (introText !== "") {
          setIntroText("");
        }
        for await (const chunk of chatIntroService.streamIntroText(
          llm_id,
          scope_guide_steps,
          scope_description,
        )) {
          if (introId !== chunk.intro_id) {
            setIntroId(chunk.intro_id);
          }
          setIntroText(prev => prev + chunk.answer);
        }
      }

      const steps = useScopeGuidanceStore.getState().steps;
      let stepsObj = null;
      if (steps) {
        stepsObj = {
          STEP_1: steps[0] || "empty",
          STEP_2: steps[1] || "empty",
          STEP_3: steps[2] || "empty",
          STEP_4: steps[3] || "empty",
          STEP_5: steps[4] || "empty",
        };
      }

      consumeStream("gpt-4o-openai", stepsObj, contextObject.description);
    }

    setIntroSet(true);
  }, [contextObject]);

  useEffect(() => {
    if (!contextObject || !introId) {
      return;
    }

    chatIntroService.saveIntroId(contextObject.id, introId);
  }, [introId]);

  // useSocket({
  //     // type: "PUMA_CHAT",
  //     // data: {
  //     //     userId: user.id,
  //     //     searchId: currentSearchId,
  //     // },
  //     callBack: handleConversation,
  // });

  useEffect(() => {
    // const socketService = new SocketService();
    // socketServiceRef.current = socketService;

    // socketService.getSocketConnection();
    // socketService.addMessageListener(handleConversation);

    // return () => {
    //   socketService.closeConnection();
    // };

    SocketService.getSocketConnection(user.id);
    SocketService.addMessageListener(handleConversation);
    return () => {
      SocketService.removeMessageListener(handleConversation);
      SocketService.closeConnection();
    };
  }, [contextObject]);

  //Handel message before it is sent
  const onEnterPress = event => {
    setShowLoading(true);
    setScrollingDown(true);
    prevScrollY.current = 0;
  };

  useEffect(() => {
    if (chatContentEl) {
      chatContentEl.current.scrollTop = chatContentEl.current.scrollHeight;
    }
  }, [
    updateUserConversationStatus,
    createUserConversationStatus,
    conversationHistoryStatus,
  ]);

  useEffect(() => {
    if (currentSearchId) {
      dispatch(getConversationHistoryRequest({ id: currentSearchId }));
    }
  }, [currentSearchId]);

  const onGetSummaryClick = () => {
    //reset scroll
    setScrollingDown(true);
    prevScrollY.current = 0;

    let payload = {
      deep_dive_question:
        "Please give an overall estimation on how well the results reflect the needs of the query. Based on that, continue into detail on the following aspects. Discuss the most represented industries or sectors represented in the results. Discuss why they might be so well-represented. Identify the most prominent problems being addressed in each of these top industries represented in the results list. Explain how these problems are related to the industry they belong to. Discuss the most commonly occurring solutions, in their application of technologies and approaches – and how they address each of the previously identified problems. Identify respectively the most uncommon, innovative and paradigm shifting solutions to the result list’s most prominent problems. Discuss why these solutions are so unique and what potential benefits they offer over the currently more common or conventional solutions. Use examples of unique selling points for companies representing both common and uncommon solutions. Possibly include potential main use cases represented between the unique selling points highlighted. Identify the main areas or locations represented among the result entries. Conclude the analysis by summarising the main points discussed. Sort the answer naturally into paragraphs",
      conversation_context: contextObject.conversation_context,
      scope_description: contextObject.description,
      summary_request: true,
      search_id: contextObject.id,
      type: "chat",
    };
    if (contextObject && contextObject.conversation_id) {
      payload.conversation_id = contextObject.conversation_id;
    }
    setShowLoading(true);
    dispatch(
      updateUserConversationRequest({ data: payload, id: contextObject.id }),
    );
  };

  const modalStatus = modalStatus => {
    setIsExampleModalOpen(modalStatus);
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const bytes = new Uint8Array(arrayBuffer);
        const binary = bytes.reduce(
          (acc, byte) => acc + String.fromCharCode(byte),
          "",
        );
        const base64String = btoa(binary);
        console.log(base64String);
        let payload = {
          type: "pdf",
          conversation_id: contextObject.conversation_id,
          file_name: file.name,
          file_bytes: base64String,
          // deep_dive_question: "User PDF upload",
          conversation_context: contextObject.conversation_context,
          scope_description: contextObject.description,
          scope_guide_steps: {
            STEP_1: contextObject.scope_guide_step_1 || "empty",
            STEP_2: contextObject.scope_guide_step_2 || "empty",
            STEP_3: contextObject.scope_guide_step_3 || "empty",
            STEP_4: contextObject.scope_guide_step_4 || "empty",
            STEP_5: contextObject.scope_guide_step_5 || "empty",
          },
        };
        if (user && user.llm_region) {
          payload.llm_region = user.llm_region;
        }
        setIsPdfLoading(true);
        payload.conversation_id
          ? dispatch(
              updateUserConversationRequest({
                data: payload,
                id: contextObject.id,
              }),
            )
          : dispatch(
              createUserConversationRequest({
                data: payload,
                id: contextObject.id,
              }),
            );
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const onScrollToSummaryClick = () => {
    if (chatContentEl) {
      const summaryAnswers = chatContentEl.current.querySelectorAll(
        '[is-summary-answer="true"]',
      );
      if (summaryAnswers.length) {
        summaryAnswers[summaryAnswers.length - 1].scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  };

  //while typing is true always scroll
  useEffect(() => {
    if (
      scrollingDown &&
      chatContentEl.current &&
      contextObject &&
      userConversationData &&
      userConversationData[contextObject.id] &&
      userConversationData[contextObject.id].conversation.length &&
      userConversationData[contextObject.id].conversation[
        userConversationData[contextObject.id].conversation.length - 1
      ].typing
    ) {
      var interval = setInterval(() => {
        chatContentEl.current.scrollTop = chatContentEl.current.scrollHeight;
      }, 10);
    }
    return () => clearInterval(interval);
  }, [contextObject, userConversationData, scrollingDown]);

  const stopTypingLastMsg = () => {
    if (
      userConversationData[contextObject.id] &&
      userConversationData[contextObject.id].conversation.length > 0
    ) {
      dispatch(
        userConversationActions.updateConversationTyping({
          id: contextObject.id,
          index: userConversationData[contextObject.id].conversation.length - 1,
          typing: false,
        }),
      );
    }
  };

  const handleScroll = () => {
    const currentScrollY = chatContentEl.current.scrollTop;
    if (currentScrollY < prevScrollY.current) {
      setScrollingDown(false); // User is scrolling up
    }
    prevScrollY.current = currentScrollY;
  };

  return (
    <div className={classes.mlChat}>
      {/* Header */}
      <div className={classes.chatHeaderContainer}>
        <div></div>
        <div className={classes.header}>
          {/* <div className={classes.pumaLogoWrapper}>
                        <img
                            src={pumaLogoSrc}
                            className={classes.pumaLogo}
                            alt="puma logo"
                        />
                    </div> */}
          <Typography variant="subtitleResult" className={classes.headerTitle}>
            PUMA <b>Deep dive</b>
          </Typography>
        </div>
        <div className={classes.infoToolTip}>
          <Tooltip
            title={
              <Typography
                color="white"
                variant="body2"
                className={classes.infoTooltipText}>
                Please enter any questions you may have to the results of your
                search. Feel free to ask into specifics on an individual company
                profile, or request lists or overview summaries on either a
                selection of companies, or on the application of technologies,
                problems to solve, etc.
              </Typography>
            }
            arrow>
            <div className={classes.infoIcon}>?</div>
          </Tooltip>
        </div>
      </div>
      {/* Conversation content */}
      <div
        className={classes.chatContent}
        ref={chatContentEl}
        onScroll={handleScroll}>
        {contextObject && (
          <>
            <div>
              <MlContent>
                <Markdown markdown_text={introText} />
              </MlContent>
            </div>
            {userConversationData &&
            userConversationData[contextObject.id] &&
            userConversationData[contextObject.id].conversation.length !== 0 ? (
              <>
                {/* conversation is not empty */}
                {/* {console.log(userConversationData[contextObject.id])} */}
                {userConversationData[contextObject.id].conversation.map(
                  (entry, index) => (
                    <div key={`conversation-content-${index}`}>
                      {entry.question && <UserContent text={entry.question} />}
                      {entry.answer && (
                        <MlContent
                          contentOptions={true}
                          text={entry.answer}
                          conversationIndex={index}
                          contextObject={contextObject}
                          conversationItem={entry}
                        />
                      )}
                    </div>
                  ),
                )}
              </>
            ) : (
              <>
                {conversationHistoryStatus === REQUEST_PENDING && (
                  <div className={classes.chatHistoryLoading}>
                    <CircularProgress
                      size="1.3rem"
                      style={{ color: "#8594AF" }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
        {/* Loading answer */}
        <div
          className={classnames([
            classes.chatLoading,
            { [classes.showLoading]: showLoading },
          ])}>
          <MlContent>
            <CircularProgress size="1.3rem" style={{ color: "#8594AF" }} />
          </MlContent>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "center",
          gap: "10px",
        }}>
        {/* Summary */}
        {hasSummary &&
        summaryHasErrorResponse === false &&
        userConversationData[contextObject.id] &&
        selectedFiltersCount === 0 ? (
          <Button
            className={classnames([
              classes.getSummaryBtn,
              classes.scrollToSummaryBtn,
            ])}
            onClick={() => {
              onScrollToSummaryClick();
            }}
            loading={showSummaryLoading}
            variant="quaternary">
            Take me to my summary
          </Button>
        ) : (
          <Button
            className={classes.pumaActionButton}
            onClick={() => {
              onGetSummaryClick();
            }}
            loading={showSummaryLoading}>
            Get Summary of Results
          </Button>
        )}

        <Button
          className={classes.pumaActionButton}
          onClick={() => setIsExampleModalOpen(true)}>
          Explore Prompt Examples
        </Button>

        <PDFUpload version="custom" handleFileChange={handleFileChange}>
          <Button className={classes.pumaActionButton}>Upload PDF</Button>
        </PDFUpload>
      </div>
      {/* User input - Textarea */}
      <div className={classes.chatInput}>
        <UserInput
          contextObject={contextObject}
          contextName={contextName}
          selectedFiltersCount={selectedFiltersCount}
          conversationContext={conversationContext}
          setShowLoading={setShowLoading}
          onEnterPress={onEnterPress}
        />
        {/* Stop typing button temporary removed until the Typewriter component is re-factored*/}
        {/* <div className={classes.stopBtnWrapper}>
          {!showLoading &&
            contextObject &&
            userConversationData[contextObject.id] &&
            userConversationData[contextObject.id].conversation.length !== 0 &&
            userConversationData[contextObject.id].conversation[
              userConversationData[contextObject.id].conversation.length - 1
            ].typing && (
              <StopPuma
                className={classes.stopPuma}
                onClick={stopTypingLastMsg}
              />
            )}
        </div> */}
      </div>
      {/* MODALS START */}
      <SuggestedPromptsModal
        contextObject={contextObject}
        childActions={() => setShowLoading(true)}
        isOpen={isExampleModalOpen}
        setIsOpen={setIsExampleModalOpen}
      />
      <ActionModal
        open={isPdfLoading}
        onClose={() => setIsPdfLoading(false)}
        title={"PUMA is reading your PDF"}
        content={
          "PUMA is currently reading your PDF. Please wait for the analysis to be completed."
        }
      />
      {/* MODALS END */}
    </div>
  );
};

export default MlChat;
